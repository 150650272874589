#maps-for-all-content .search-suggestion-container {
  position: absolute;
  z-index: 1000;
  top: 44px;
  width: calc(100% - 101px);
  padding: 10px 0 10px 10px;
  background-color: white;
  border: 1px solid var(--lightgray);
  border-top: 1px solid var(--bluish-gray);
  border-radius: 0 0 4px 4px;
  font-size: 12px;
  color: #333;
  cursor: pointer;
}

#maps-for-all-content .search-suggestion-container a {
  color: #333;
  text-decoration: none;
  width: 100%;
}
