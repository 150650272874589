@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,800|Zilla+Slab:400,600,700);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
#maps-for-all-content .navigation {
    font-family: var(--sans-serif);
    width: 100%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    z-index: 1000;
    -webkit-backface-visibility: hidden;
    background-image: url(/static/media/wfp-majority-black-bg.6722fe7d.png);
    background-repeat: repeat;
    background-size: 300px 100px;
    background-color: var(--blue); /* rather than actually going through a changing the variables in line, keep them updated in the _variable.css file */

}

#maps-for-all-content .navigation .navigation-area a.logo-area {
    position: relative;
}

#maps-for-all-content .navigation .navigation-area a.logo-area img {
    max-height: 125px;
}


#maps-for-all-content .navigation .navigation-area {
    width: 100%;
    padding: 10px 20px;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

#maps-for-all-content .navigation nav ul.link-list {
    display: flex;
    flex-direction: row;


}

#maps-for-all-content .navigation nav ul.link-list li {
    margin-left: 25px;
}

#maps-for-all-content .navigation nav ul.link-list li a {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.5px;
    color: white;
}

#maps-for-all-content .navigation nav ul.link-list li.mobile-nav-open { display: none; }

#maps-for-all-content .navigation nav.mobile-nav {
    display: none;
}


/*Mobile*/
@media only screen and (max-width: 900px) {
    #maps-for-all-content .navigation {
        width: calc(100% - 10px);
        padding-left: 5px;
        padding-right: 5px;

        flex-direction: column-reverse;
    }

    #maps-for-all-content .navigation .navigation-area {
        width: 100%;
        padding: 0;

    }

    #maps-for-all-content .navigation .navigation-area .logo-img {
        width: 150px !important;
    }

    #maps-for-all-content .navigation .navigation-area a.logo-area:before {
        bottom: 15px;
        right: -30px;
        transform: rotate(-12deg);
    
    }

    #maps-for-all-content .navigation nav {
        margin-bottom: 20px;
    }

    #maps-for-all-content .highlight-header {
        display:none;
    }

    #maps-for-all-content .navigation nav ul.link-list {
        margin-top: 10px;
    }

    #maps-for-all-content .navigation nav ul.link-list li {
        margin-left: 10px;
        display: none;
    }

    #maps-for-all-content .navigation nav ul.link-list li a {
        font-size: 12px;
    }


    #maps-for-all-content .navigation nav ul.link-list li.mobile-nav-open {
      display: block !important;
      padding: 0 10px 0 0;
    }

    #maps-for-all-content .navigation nav ul.link-list li.mobile-nav-open a span { color: white; }

    #maps-for-all-content .navigation nav.mobile-nav {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: var(--blue);
        background-image: url(/static/media/wfp-majority-black-bg.6722fe7d.png);
        background-repeat: repeat;
        background-size: 250px 100px;
        top: 0;
        left: 0;
        display: block;
    }

    #maps-for-all-content .navigation nav.mobile-nav .mobile-nav-viewport {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #maps-for-all-content .navigation nav.mobile-nav .mobile-nav-close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
    }

    #maps-for-all-content .navigation nav.mobile-nav ul {
        transform: translateY(-20%);
    }
    #maps-for-all-content .navigation nav.mobile-nav ul li {
        padding: 10px;
    }

    #maps-for-all-content .navigation nav.mobile-nav ul li a {
        color: white;
        font-size: 18px;
        font-weight: 800;
    }

    #maps-for-all-content .navigation nav.mobile-nav ul li a.donate-area {
        padding: 5px;
    }

    
}

#maps-for-all-content .navigation .donate-button {
    background-color: var(--highlight) !important;

    color: #fff!important;
    font-size: 16px!important;
    text-transform: uppercase;
    padding: 12px 40px;
    font-weight: 600;
    letter-spacing: 1px;
    border-top: 1px solid hsla(0,0%,100%,.5);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);

    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: #fff;
}

#maps-for-all-content .navigation .donate-button:hover {
  background-color: var(--light-blue) !important;
}


#maps-for-all-content .navigation nav.mobile-nav .mobile-list {
    text-align: center;

  }

#maps-for-all-content .navigation nav.mobile-nav .mobile-donate-button {
    background-color: var(--highlight) !important;

    color: #fff!important;
    font-size: 16px!important;
    text-transform: uppercase;
    padding: 12px 40px;
    font-weight: 600;
    letter-spacing: 1px;
    border-top: 1px solid hsla(0,0%,100%,.5);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);

    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: #fff;
    display: block;
}

#maps-for-all-content .navigation nav.mobile-nav .mobile-donate-button:hover {
  background-color: var(--light-blue) !important;
}


/* Activist Header CSS */

#maps-for-all-content .highlight-header {
    font-family: var(--sans-serif);
    width: 100%;
    padding: 1%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */

    box-shadow: 0 2px 2px rgba(0,0,0,.2);


    -webkit-backface-visibility: hidden;
    background-color: var(--light-blue); /* rather than actually going through a changing the variables in line, keep them updated in the _variable.css file */

}

#maps-for-all-content .navigation .activist-header {
    display: block;
    justify-content: center;
    align-items: center;
    left: 0; right: 0; bottom: 0;
    background: #ff4444;
    padding: 3px 8px;
    color: #fff;
    z-index: 1;
}

#maps-for-all-content .highlight-header .activist-button {
    background-color: var(--light-blue) !important;

    font-size: 16px!important;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: var(--highlight);
}


/* Paid For Footer CSS */

#maps-for-all-content .paid-for-footer {
    font-family: var(--sans-serif);
    width: 100%;
    padding: 0.75%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 2px 2px rgba(0,0,0,.2);

    position: fixed;
    z-index: 1000;
    bottom: 0px;
    -webkit-backface-visibility: hidden;
    background-color: var(--blue); /* rather than actually going through a changing the variables in line, keep them updated in the _variable.css file */

}

#maps-for-all-content .navigation .paid-for-header {
    display: block;
    justify-content: center;
    align-items: center;
    left: 0; right: 0; bottom: 0;
    background: #ff4444;
    padding: 3px 8px;
    color: #fff;
    z-index: 1;
}

#maps-for-all-content .paid-for-footer .paid-for-button {

    font-size: 10px!important;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: .5px;
    color: #fff;
}

/*Medium Screen*/
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    #maps-for-all-content .navigation {
        width: 100%;
    }
}

/*Widescreen*/
@media only screen and (min-width: 1600px) {

}


#maps-for-all-content .event-list-item-container {
    padding: 20px;
    display: flex;
}

#maps-for-all-content .wfp-bling {
    border-left: 0.5em solid var(--highlight);

}

#maps-for-all-content .priority-bling {
    /* outline: 0.5em solid var(--light-blue); */
    /* background-color: #ebfcf7; */

}

.fa-thumb-tack {
    color: var(--highlight);
    transform: rotate(30deg);
}

.map-pinned-event .fa-thumb-tack {
    font-size: 1.5rem;
    margin-left: 0.75rem;
    margin-top: 0.75rem;
}
#maps-for-all-content .priority-bling .fa-thumb-tack {
    align-self: center;
    font-size: 1.5rem;
    margin-top: -0.5rem;
    /* margin-right: rem; */
}

#maps-for-all-content .event-list-item-container .event-list-item-title {
    margin-top: 5px;
    /* margin-bottom: 10px; */
}

#maps-for-all-content .event-list-item-container .event-list-item-title a {
    text-decoration: none;
    color: var(--blue);
    font-weight: 600;
}

#maps-for-all-content .event-list-item-container .event-list-item-info {
    flex-grow: 1;
    flex-basis: 0;
}

#maps-for-all-content .event-list-item-container .event-list-item-date {
    color: var(--darkgray);
    font-size: 14px;
    font-weight: 600;
    width: 65px;
}
#maps-for-all-content .event-list-item-container .event-list-date-view {
    /* border: 1px solid var(--darkgray); */
    padding: 5px;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding-top: 7px;
}

#maps-for-all-content .event-list-item-container .event-list-item-description {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.event-list-item-container .event-list-day-data {
    font-size: 12px;
    text-transform: uppercase;
    color: #333;
    letter-spacing: 1px;
    text-shadow: 0px 1px 1px rgba(0,0,0,0.2);
}

#maps-for-all-content .event-list-item-container .event-list-time-data,
#maps-for-all-content .event-list-item-container .event-list-item-desc span {
    margin-top: 2px;
}
#maps-for-all-content .event-list-item-container .event-list-item-desc {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
}
#maps-for-all-content .event-list-item-container .event-list-item-desc span {
    flex-grow: 1;
    flex-basis: 0;
}

#maps-for-all-content .event-list-item-container .event-list-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
    display: inline-block;
    text-align: center;

    opacity: 0.6;
}

#maps-for-all-content .event-list-item-container .wfp-event-list-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
    display: inline-block;
    text-align: center;
    filter: invert(28%) sepia(60%) saturate(6352%) hue-rotate(264deg) brightness(96%) contrast(101%);
    opacity: 0.8;
}

#maps-for-all-content .event-list-item-container .event-list-date-data {
    font-size: 24px;
    letter-spacing: 1px;
    color: #333;
    font-weight: 800;
}

#maps-for-all-content .event-list-item-container .event-list-month-data {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #333;
    /* text-shadow: 0px 1px 1px rgba(0,0,0,0.2); */
}

#maps-for-all-content .event-list-item-container .event-list-item-type span {
    border-radius: 4px;
    display: inline-block;
    background-color: var(--lightgray);
    border: 1px solid var(--lightgray);
    margin-bottom: 0.3em;
    padding: 2px 5px 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: var(--sans-serif);
    font-size: 10px;
}

#maps-for-all-content .event-list-item-container .event-list-item-cta a.event-list-item-cta-btn {
    background-color: transparent;
    border-color: var(--blue);
    border-width: 2px;
    border-style: solid;
    color: var(--blue);
    text-decoration: none;
    margin-top: 5px;
    font-size: 10px;
    letter-spacing: 2px;
    font-weight: 800;
}

#maps-for-all-content .event-list-item-container .event-list-item-cta a.event-list-item-cta-btn:hover {
    color: white;
    background-color: var(--blue);
}

#maps-for-all-content .event-bundled-loc {
    display: flex;
    padding: 10px;
    background-color: #f3ebff;
}

#maps-for-all-content .event-bundled-loc img {
    width: 20px;
    height: 20px;
    opacity: 0.6;
    margin-right: 5px;
}
#maps-for-all-content .event-bundled-loc div {
    flex-grow: 1;
    flex-basis: 0;
}

#maps-for-all-content .event-bundled-loc div h1{
    font-size: 18px;
    letter-spacing: 0.6px;
    font-weight: 600;
    margin-bottom: 2px;
}

#maps-for-all-content .event-bundled-loc div h2 {
    font-size: 13px;
}

#maps-for-all-content .event-bundled-item.event-list-item-container {
    margin-left: 20px;
    padding: 5px 10px 5px;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-item-date .event-list-date-view {
    margin-top: 0;
    width: 50px;

    padding-top: 9px;
    padding-bottom: 0;
    
    line-height: 13px;
}
#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-item-date {
    width: 55px;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-date-data {
    font-size: 14px;
    font-weight: 600;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-day-data {
    font-size: 10px;
    font-weight: 400;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-month-data {
    font-size: 11px;
    letter-spacing: 1px;
}

#maps-for-all-content .event-list-item-container .event-list-item-desc.event-list-item-event-type {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0;
}
#maps-for-all-content .event-bundled-item.event-list-item-container a.event-list-item-cta-btn {
    background-color: transparent;
    border-color: var(--blue);
    border-width: 2px;
    border-style: solid;
    color: var(--blue);
    text-decoration: none;
    margin-top: 5px;
    font-size: 10px;
    letter-spacing: 2px;
    font-weight: 800;
}
#maps-for-all-content .popup-item {
      min-width: 300px;
      max-width: 350px;
      position: relative;
      font-family: var(--sans-serif);
}

#maps-for-all-content .popup-item .event-bundled-items{
    max-height: 240px;
    overflow: auto;
}

#maps-for-all-content .popup-item .popup-close {
    position: absolute;
    right: 5px;
    top: 0px;
    text-decoration: none;
    color: black;
    font-weight: 800;
    font-size: 15px;
}
#maps-for-all-content .popup-item .header-area {
    padding: 10px;
    background-color: var(--light-blue);
}
#maps-for-all-content .popup-item .content-area {
    padding: 10px;
    font-family: var(--sans-serif);
}
#maps-for-all-content .popup-item h1 {
    font-size: 18px;
    font-weight: 600;
    font-family: var(--sans-serif);
}

#maps-for-all-content .popup-item h1 a {
    color: var(--blue);
    text-decoration: none;
}

#maps-for-all-content .popup-item h3 { margin-bottom: 0; }
#maps-for-all-content .popup-item h3 span { font-size: 14px; font-weight: 600; }
#maps-for-all-content .popup-item h3 img { width: 20px; }
#maps-for-all-content .popup-item h3 * { vertical-align: middle; }
#maps-for-all-content .popup-item sub {
    text-transform: uppercase;
    font-size: 8px;
    line-height: 1;
    text-align: right;
    margin-right: 5px;
}

#maps-for-all-content .popup-item .sponsor-area {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--light-blue);
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#maps-for-all-content .popup-item a.rsvp {
    display: inline-block;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 24px;

    background-color: var(--highlight);
    color: var(--blue);
    text-decoration: none;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;

}

#maps-for-all-content .popup-item a.rsvp:hover {
    background-color: var(--blue);
    color: white;
}

#maps-for-all-content .popup-item p {
    font-size: 14px;
    line-height: 24px;
}


#maps-for-all-content div.host-btn-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

#maps-for-all-content div.host-btn-container a.host-btn {
    
    display: inline-block;
    
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 4px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    border-top: rgba(255,255,255,0.2);
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    font-family: var(--sans-serif);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

    background-color: var(--blue);
    color: white;
}

#maps-for-all-content div.host-btn-container a.host-btn:hover {
    background-color: var(--highlight);
    color: var(--blue);
}

@media screen and (max-width: 600px) {
    #maps-for-all-content div.host-btn-container { 
        position: relative;
        top: auto;
        right: auto;
    }    

    #maps-for-all-content div.host-btn-container a.host-btn {
        width: 100%;
        padding: 16px 0;
        text-align: center;
        box-shadow: 0 2px 3px rgba(0,0,0,0.3);
        border-radius: 0;
    }
}
#maps-for-all-content .map-area {
  flex-grow: 1;
  background-color: var(--bluish-gray);
  height: 100%;
}

#maps-for-all-content .mapboxgl-popup-content {
  padding: 0 !important;
}


#maps-for-all-content .event-list-item {
    /* border-bottom: 1px dashed gray; */
    font-family: var(--sans-serif);
    border-left: 4px solid gray;
    margin-top: 5px;
    margin-bottom: 3px;
    background-color: whitesmoke;
    width: calc(100% - 5px);
}

#maps-for-all-content .event-list-item:first-child {
    margin-top: 0;
}

#maps-for-all-content .event-list-item:last-child {
    margin-bottom: 0;
    
}

#maps-for-all-content .event-list-container {
    flex-grow: 1;
    background-color: white; 
    overflow: auto;
    display: flex;
    -webkit-overflow-scrolling: touch;
}

#maps-for-all-content .event-list {
    width: 100%;
}
#maps-for-all-content .search-suggestion-container {
  position: absolute;
  z-index: 1000;
  top: 44px;
  width: calc(100% - 101px);
  padding: 10px 0 10px 10px;
  background-color: white;
  border: 1px solid var(--lightgray);
  border-top: 1px solid var(--bluish-gray);
  border-radius: 0 0 4px 4px;
  font-size: 12px;
  color: #333;
  cursor: pointer;
}

#maps-for-all-content .search-suggestion-container a {
  color: #333;
  text-decoration: none;
  width: 100%;
}

#maps-for-all-content 
.search-container {
    background-color: var(--bluish-gray);
    border-bottom: 1px solid var(--gray);
    font-family: var(--sans-serif);
}

#maps-for-all-content 
.search-viewport {
    padding: 10px;
    position: relative;
}
#maps-for-all-content 
.search-form {
    display: flex;
    
}

#maps-for-all-content 
.search-form .search-input {
  position: relative;
  flex-grow: 1;
}

#maps-for-all-content 
.search-text {
    display: block;
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--dark-gray);
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid var(--lightgray);
    border-radius: 4px 0 0 4px;
    height: 25px;
    text-align: center;
    font-weight: 800;

    font-family: var(--sans-serif);
}

#maps-for-all-content 
.search-btn {
    height: 25px;
    border-radius: 0 4px 4px 0;
    background-color: var(--blue);
    color: white;
    padding: 6px 10px 4px;
    text-decoration: none;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    border: 1px solid var(--blue);
    font-family: var(--sans-serif);
}
#maps-for-all-content 
.search-btn:hover {
    color: white;
}

#maps-for-all-content 
.filter-form ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
}

#maps-for-all-content 
.filter-form ul li {
    margin: 2px 2px 2px 0;
    border-radius: 2px;
    border: 1px solid var(--lightgray);
}


#maps-for-all-content 
.filter-form ul li label {
    font-size: 10px;
    text-transform: uppercase;
    font-family: var(--sans-serif);
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

#maps-for-all-content 
.filter-form ul li label img{
    height: 14px;
    margin-right: 4px;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    opacity: 0.5;
}

#maps-for-all-content 
.filter-form ul li input {
    display: none;
}

#maps-for-all-content 
.filter-form ul li input:checked + label {
    /* color: blue; */
    font-weight: 600;
    background-color: var(--blue);
    color: white;
    display: flex;
    align-items: center; 
}

#maps-for-all-content .filter-form ul li input + label {
    padding: 4px;
    color: gray;
}
#maps-for-all-content .filter-form ul li input + label .event-check {
    display: none
}

#maps-for-all-content .filter-form ul li input:checked + label .event-check { 
    display: block;
}

#maps-for-all-content .filter-form ul li input:checked + label img {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
    opacity: 1;
}

@font-face {
    font-family: klima;
    src:
        url(/stylesheets/vendor/fonts/klima/Klima-Regular.otf) format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: klima;
    src: 
        url(/stylesheets/vendor/fonts/klima/Klima-Bold.otf) format("opentype");
    font-weight: 600;
    font-style: normal;
}

:root {
    --blue: #141414; /* actually WFM Black*/
    --lightgray: #ccced0; /* actually dust white */
    --gray: #ccced0;
    --bluish-gray: #ccced0; /* also dust white */
    --darkgray: #141414; /* actually black */
    --sans-serif: 'Montserrat', sans-serif;
    --light-blue: #6BD6B4; /* Let's make it WFM Aqua*/
    --highlight: #972DF6; /* Make it WFM Magenta*/
}

#maps-for-all-content .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 4px 10px 4px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 4px;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#maps-for-all-content a {
  text-decoration: none;
}

/* RESET */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, #maps-for-all-content #maps-for-all-content div, #maps-for-all-content #maps-for-all-content span, #maps-for-all-content #maps-for-all-content applet, #maps-for-all-content #maps-for-all-content object, #maps-for-all-content #maps-for-all-content iframe, 
#maps-for-all-content h1, #maps-for-all-content h2, #maps-for-all-content h3, #maps-for-all-content h4, #maps-for-all-content h5, #maps-for-all-content h6, #maps-for-all-content p, #maps-for-all-content blockquote, #maps-for-all-content pre, 
#maps-for-all-content a, #maps-for-all-content abbr, #maps-for-all-content acronym, #maps-for-all-content address, #maps-for-all-content big, #maps-for-all-content cite, #maps-for-all-content code, 
#maps-for-all-content del, #maps-for-all-content dfn, #maps-for-all-content em, #maps-for-all-content img, #maps-for-all-content ins, #maps-for-all-content kbd, #maps-for-all-content q, #maps-for-all-content s, #maps-for-all-content samp, 
#maps-for-all-content small, #maps-for-all-content strike, #maps-for-all-content strong, #maps-for-all-content sub, #maps-for-all-content sup, #maps-for-all-content tt, #maps-for-all-content var, 
#maps-for-all-content b, #maps-for-all-content u, #maps-for-all-content i, #maps-for-all-content center, 
#maps-for-all-content dl, #maps-for-all-content dt, #maps-for-all-content dd, #maps-for-all-content ol, #maps-for-all-content ul, #maps-for-all-content li, 
#maps-for-all-content fieldset, #maps-for-all-content form, #maps-for-all-content label, #maps-for-all-content legend, 
#maps-for-all-content table, #maps-for-all-content caption, #maps-for-all-content tbody, #maps-for-all-content tfoot, #maps-for-all-content thead, #maps-for-all-content tr, #maps-for-all-content th, #maps-for-all-content td, 
#maps-for-all-content article, #maps-for-all-content aside, #maps-for-all-content canvas, #maps-for-all-content details, #maps-for-all-content embed, 
#maps-for-all-content figure, #maps-for-all-content figcaption, #maps-for-all-content footer, #maps-for-all-content header, #maps-for-all-content hgroup, 
#maps-for-all-content menu, #maps-for-all-content nav, #maps-for-all-content output, #maps-for-all-content ruby, #maps-for-all-content section, #maps-for-all-content summary, 
#maps-for-all-content time, #maps-for-all-content mark, #maps-for-all-content audio, #maps-for-all-content video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
#maps-for-all-content article, #maps-for-all-content aside, #maps-for-all-content details, #maps-for-all-content figcaption, #maps-for-all-content figure, 
#maps-for-all-content footer, #maps-for-all-content header, #maps-for-all-content hgroup, #maps-for-all-content menu, #maps-for-all-content nav, #maps-for-all-content section {
	display: block;
}
body {
	line-height: 1;
}
#maps-for-all-content ol, #maps-for-all-content ul {
	list-style: none;
}
#maps-for-all-content blockquote, #maps-for-all-content q {
	quotes: none;
}
#maps-for-all-content blockquote:before, #maps-for-all-content blockquote:after,
#maps-for-all-content q:before, #maps-for-all-content q:after {
	content: '';
	content: none;
}
#maps-for-all-content table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body {
  height: 100%;
}

