#maps-for-all-content .popup-item {
      min-width: 300px;
      max-width: 350px;
      position: relative;
      font-family: var(--sans-serif);
}

#maps-for-all-content .popup-item .event-bundled-items{
    max-height: 240px;
    overflow: auto;
}

#maps-for-all-content .popup-item .popup-close {
    position: absolute;
    right: 5px;
    top: 0px;
    text-decoration: none;
    color: black;
    font-weight: 800;
    font-size: 15px;
}
#maps-for-all-content .popup-item .header-area {
    padding: 10px;
    background-color: var(--light-blue);
}
#maps-for-all-content .popup-item .content-area {
    padding: 10px;
    font-family: var(--sans-serif);
}
#maps-for-all-content .popup-item h1 {
    font-size: 18px;
    font-weight: 600;
    font-family: var(--sans-serif);
}

#maps-for-all-content .popup-item h1 a {
    color: var(--blue);
    text-decoration: none;
}

#maps-for-all-content .popup-item h3 { margin-bottom: 0; }
#maps-for-all-content .popup-item h3 span { font-size: 14px; font-weight: 600; }
#maps-for-all-content .popup-item h3 img { width: 20px; }
#maps-for-all-content .popup-item h3 * { vertical-align: middle; }
#maps-for-all-content .popup-item sub {
    text-transform: uppercase;
    font-size: 8px;
    line-height: 1;
    text-align: right;
    margin-right: 5px;
}

#maps-for-all-content .popup-item .sponsor-area {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--light-blue);
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#maps-for-all-content .popup-item a.rsvp {
    display: inline-block;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 24px;

    background-color: var(--highlight);
    color: var(--blue);
    text-decoration: none;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;

}

#maps-for-all-content .popup-item a.rsvp:hover {
    background-color: var(--blue);
    color: white;
}

#maps-for-all-content .popup-item p {
    font-size: 14px;
    line-height: 24px;
}
