#maps-for-all-content .event-bundled-loc {
    display: flex;
    padding: 10px;
    background-color: #f3ebff;
}

#maps-for-all-content .event-bundled-loc img {
    width: 20px;
    height: 20px;
    opacity: 0.6;
    margin-right: 5px;
}
#maps-for-all-content .event-bundled-loc div {
    flex-grow: 1;
    flex-basis: 0;
}

#maps-for-all-content .event-bundled-loc div h1{
    font-size: 18px;
    letter-spacing: 0.6px;
    font-weight: 600;
    margin-bottom: 2px;
}

#maps-for-all-content .event-bundled-loc div h2 {
    font-size: 13px;
}

#maps-for-all-content .event-bundled-item.event-list-item-container {
    margin-left: 20px;
    padding: 5px 10px 5px;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-item-date .event-list-date-view {
    margin-top: 0;
    width: 50px;

    padding-top: 9px;
    padding-bottom: 0;
    
    line-height: 13px;
}
#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-item-date {
    width: 55px;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-date-data {
    font-size: 14px;
    font-weight: 600;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-day-data {
    font-size: 10px;
    font-weight: 400;
}

#maps-for-all-content .event-bundled-item.event-list-item-container .event-list-month-data {
    font-size: 11px;
    letter-spacing: 1px;
}

#maps-for-all-content .event-list-item-container .event-list-item-desc.event-list-item-event-type {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0;
}
#maps-for-all-content .event-bundled-item.event-list-item-container a.event-list-item-cta-btn {
    background-color: transparent;
    border-color: var(--blue);
    border-width: 2px;
    border-style: solid;
    color: var(--blue);
    text-decoration: none;
    margin-top: 5px;
    font-size: 10px;
    letter-spacing: 2px;
    font-weight: 800;
}