
#maps-for-all-content div.host-btn-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

#maps-for-all-content div.host-btn-container a.host-btn {
    
    display: inline-block;
    
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 4px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    border-top: rgba(255,255,255,0.2);
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    font-family: var(--sans-serif);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;

    background-color: var(--blue);
    color: white;
}

#maps-for-all-content div.host-btn-container a.host-btn:hover {
    background-color: var(--highlight);
    color: var(--blue);
}

@media screen and (max-width: 600px) {
    #maps-for-all-content div.host-btn-container { 
        position: relative;
        top: auto;
        right: auto;
    }    

    #maps-for-all-content div.host-btn-container a.host-btn {
        width: 100%;
        padding: 16px 0;
        text-align: center;
        box-shadow: 0 2px 3px rgba(0,0,0,0.3);
        border-radius: 0;
    }
}