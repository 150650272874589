
#maps-for-all-content .event-list-item {
    /* border-bottom: 1px dashed gray; */
    font-family: var(--sans-serif);
    border-left: 4px solid gray;
    margin-top: 5px;
    margin-bottom: 3px;
    background-color: whitesmoke;
    width: calc(100% - 5px);
}

#maps-for-all-content .event-list-item:first-child {
    margin-top: 0;
}

#maps-for-all-content .event-list-item:last-child {
    margin-bottom: 0;
    
}