#maps-for-all-content .navigation {
    font-family: var(--sans-serif);
    width: 100%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    z-index: 1000;
    -webkit-backface-visibility: hidden;
    background-image: url("../assets/2022 update/wfp-majority-black-bg.png");
    background-repeat: repeat;
    background-size: 300px 100px;
    background-color: var(--blue); /* rather than actually going through a changing the variables in line, keep them updated in the _variable.css file */

}

#maps-for-all-content .navigation .navigation-area a.logo-area {
    position: relative;
}

#maps-for-all-content .navigation .navigation-area a.logo-area img {
    max-height: 125px;
}


#maps-for-all-content .navigation .navigation-area {
    width: 100%;
    padding: 10px 20px;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

#maps-for-all-content .navigation nav ul.link-list {
    display: flex;
    flex-direction: row;


}

#maps-for-all-content .navigation nav ul.link-list li {
    margin-left: 25px;
}

#maps-for-all-content .navigation nav ul.link-list li a {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.5px;
    color: white;
}

#maps-for-all-content .navigation nav ul.link-list li.mobile-nav-open { display: none; }

#maps-for-all-content .navigation nav.mobile-nav {
    display: none;
}


/*Mobile*/
@media only screen and (max-width: 900px) {
    #maps-for-all-content .navigation {
        width: calc(100% - 10px);
        padding-left: 5px;
        padding-right: 5px;

        flex-direction: column-reverse;
    }

    #maps-for-all-content .navigation .navigation-area {
        width: 100%;
        padding: 0;

    }

    #maps-for-all-content .navigation .navigation-area .logo-img {
        width: 150px !important;
    }

    #maps-for-all-content .navigation .navigation-area a.logo-area:before {
        bottom: 15px;
        right: -30px;
        transform: rotate(-12deg);
    
    }

    #maps-for-all-content .navigation nav {
        margin-bottom: 20px;
    }

    #maps-for-all-content .highlight-header {
        display:none;
    }

    #maps-for-all-content .navigation nav ul.link-list {
        margin-top: 10px;
    }

    #maps-for-all-content .navigation nav ul.link-list li {
        margin-left: 10px;
        display: none;
    }

    #maps-for-all-content .navigation nav ul.link-list li a {
        font-size: 12px;
    }


    #maps-for-all-content .navigation nav ul.link-list li.mobile-nav-open {
      display: block !important;
      padding: 0 10px 0 0;
    }

    #maps-for-all-content .navigation nav ul.link-list li.mobile-nav-open a span { color: white; }

    #maps-for-all-content .navigation nav.mobile-nav {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: var(--blue);
        background-image: url("../assets/2022 update/wfp-majority-black-bg.png");
        background-repeat: repeat;
        background-size: 250px 100px;
        top: 0;
        left: 0;
        display: block;
    }

    #maps-for-all-content .navigation nav.mobile-nav .mobile-nav-viewport {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #maps-for-all-content .navigation nav.mobile-nav .mobile-nav-close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
    }

    #maps-for-all-content .navigation nav.mobile-nav ul {
        transform: translateY(-20%);
    }
    #maps-for-all-content .navigation nav.mobile-nav ul li {
        padding: 10px;
    }

    #maps-for-all-content .navigation nav.mobile-nav ul li a {
        color: white;
        font-size: 18px;
        font-weight: 800;
    }

    #maps-for-all-content .navigation nav.mobile-nav ul li a.donate-area {
        padding: 5px;
    }

    
}

#maps-for-all-content .navigation .donate-button {
    background-color: var(--highlight) !important;

    color: #fff!important;
    font-size: 16px!important;
    text-transform: uppercase;
    padding: 12px 40px;
    font-weight: 600;
    letter-spacing: 1px;
    border-top: 1px solid hsla(0,0%,100%,.5);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);

    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: #fff;
}

#maps-for-all-content .navigation .donate-button:hover {
  background-color: var(--light-blue) !important;
}


#maps-for-all-content .navigation nav.mobile-nav .mobile-list {
    text-align: center;

  }

#maps-for-all-content .navigation nav.mobile-nav .mobile-donate-button {
    background-color: var(--highlight) !important;

    color: #fff!important;
    font-size: 16px!important;
    text-transform: uppercase;
    padding: 12px 40px;
    font-weight: 600;
    letter-spacing: 1px;
    border-top: 1px solid hsla(0,0%,100%,.5);
    box-shadow: 0 2px 2px rgba(0,0,0,.2);

    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: #fff;
    display: block;
}

#maps-for-all-content .navigation nav.mobile-nav .mobile-donate-button:hover {
  background-color: var(--light-blue) !important;
}


/* Activist Header CSS */

#maps-for-all-content .highlight-header {
    font-family: var(--sans-serif);
    width: 100%;
    padding: 1%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */

    box-shadow: 0 2px 2px rgba(0,0,0,.2);


    -webkit-backface-visibility: hidden;
    background-color: var(--light-blue); /* rather than actually going through a changing the variables in line, keep them updated in the _variable.css file */

}

#maps-for-all-content .navigation .activist-header {
    display: block;
    justify-content: center;
    align-items: center;
    left: 0; right: 0; bottom: 0;
    background: #ff4444;
    padding: 3px 8px;
    color: #fff;
    z-index: 1;
}

#maps-for-all-content .highlight-header .activist-button {
    background-color: var(--light-blue) !important;

    font-size: 16px!important;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: .5px;
    color: var(--highlight);
}


/* Paid For Footer CSS */

#maps-for-all-content .paid-for-footer {
    font-family: var(--sans-serif);
    width: 100%;
    padding: 0.75%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 2px 2px rgba(0,0,0,.2);

    position: fixed;
    z-index: 1000;
    bottom: 0px;
    -webkit-backface-visibility: hidden;
    background-color: var(--blue); /* rather than actually going through a changing the variables in line, keep them updated in the _variable.css file */

}

#maps-for-all-content .navigation .paid-for-header {
    display: block;
    justify-content: center;
    align-items: center;
    left: 0; right: 0; bottom: 0;
    background: #ff4444;
    padding: 3px 8px;
    color: #fff;
    z-index: 1;
}

#maps-for-all-content .paid-for-footer .paid-for-button {

    font-size: 10px!important;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: .5px;
    color: #fff;
}

/*Medium Screen*/
@media only screen and (min-width: 901px) and (max-width: 1200px) {
    #maps-for-all-content .navigation {
        width: 100%;
    }
}

/*Widescreen*/
@media only screen and (min-width: 1600px) {

}

