#maps-for-all-content .event-list-item-container {
    padding: 20px;
    display: flex;
}

#maps-for-all-content .wfp-bling {
    border-left: 0.5em solid var(--highlight);

}

#maps-for-all-content .priority-bling {
    /* outline: 0.5em solid var(--light-blue); */
    /* background-color: #ebfcf7; */

}

.fa-thumb-tack {
    color: var(--highlight);
    transform: rotate(30deg);
}

.map-pinned-event .fa-thumb-tack {
    font-size: 1.5rem;
    margin-left: 0.75rem;
    margin-top: 0.75rem;
}
#maps-for-all-content .priority-bling .fa-thumb-tack {
    align-self: center;
    font-size: 1.5rem;
    margin-top: -0.5rem;
    /* margin-right: rem; */
}

#maps-for-all-content .event-list-item-container .event-list-item-title {
    margin-top: 5px;
    /* margin-bottom: 10px; */
}

#maps-for-all-content .event-list-item-container .event-list-item-title a {
    text-decoration: none;
    color: var(--blue);
    font-weight: 600;
}

#maps-for-all-content .event-list-item-container .event-list-item-info {
    flex-grow: 1;
    flex-basis: 0;
}

#maps-for-all-content .event-list-item-container .event-list-item-date {
    color: var(--darkgray);
    font-size: 14px;
    font-weight: 600;
    width: 65px;
}
#maps-for-all-content .event-list-item-container .event-list-date-view {
    /* border: 1px solid var(--darkgray); */
    padding: 5px;
    font-weight: 600;
    margin-right: 10px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding-top: 7px;
}

#maps-for-all-content .event-list-item-container .event-list-item-description {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.event-list-item-container .event-list-day-data {
    font-size: 12px;
    text-transform: uppercase;
    color: #333;
    letter-spacing: 1px;
    text-shadow: 0px 1px 1px rgba(0,0,0,0.2);
}

#maps-for-all-content .event-list-item-container .event-list-time-data,
#maps-for-all-content .event-list-item-container .event-list-item-desc span {
    margin-top: 2px;
}
#maps-for-all-content .event-list-item-container .event-list-item-desc {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
}
#maps-for-all-content .event-list-item-container .event-list-item-desc span {
    flex-grow: 1;
    flex-basis: 0;
}

#maps-for-all-content .event-list-item-container .event-list-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
    display: inline-block;
    text-align: center;

    opacity: 0.6;
}

#maps-for-all-content .event-list-item-container .wfp-event-list-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
    display: inline-block;
    text-align: center;
    filter: invert(28%) sepia(60%) saturate(6352%) hue-rotate(264deg) brightness(96%) contrast(101%);
    opacity: 0.8;
}

#maps-for-all-content .event-list-item-container .event-list-date-data {
    font-size: 24px;
    letter-spacing: 1px;
    color: #333;
    font-weight: 800;
}

#maps-for-all-content .event-list-item-container .event-list-month-data {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #333;
    /* text-shadow: 0px 1px 1px rgba(0,0,0,0.2); */
}

#maps-for-all-content .event-list-item-container .event-list-item-type span {
    border-radius: 4px;
    display: inline-block;
    background-color: var(--lightgray);
    border: 1px solid var(--lightgray);
    margin-bottom: 0.3em;
    padding: 2px 5px 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: var(--sans-serif);
    font-size: 10px;
}

#maps-for-all-content .event-list-item-container .event-list-item-cta a.event-list-item-cta-btn {
    background-color: transparent;
    border-color: var(--blue);
    border-width: 2px;
    border-style: solid;
    color: var(--blue);
    text-decoration: none;
    margin-top: 5px;
    font-size: 10px;
    letter-spacing: 2px;
    font-weight: 800;
}

#maps-for-all-content .event-list-item-container .event-list-item-cta a.event-list-item-cta-btn:hover {
    color: white;
    background-color: var(--blue);
}
