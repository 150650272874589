@font-face {
    font-family: klima;
    src:
        url(/stylesheets/vendor/fonts/klima/Klima-Regular.otf) format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: klima;
    src: 
        url(/stylesheets/vendor/fonts/klima/Klima-Bold.otf) format("opentype");
    font-weight: 600;
    font-style: normal;
}
